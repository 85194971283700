/* eslint-disable no-return-assign */
import axios from '@axios'
import Vue from 'vue'
import { reactive } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

// DECLARE DATE
const date = new Date()
// FORMAT DATE
const pst = date.toLocaleString('en-US', {
  timeZone: 'America/Los_Angeles',
})
// eslint-disable-next-line no-unused-vars
const today = moment(pst).format('YYYY-MM-DD')

export default {
  namespaced: true,
  state: reactive({
    tasks: {},
    task: {
      status: '',
      description: '',
      notes: '',
      mileage_transpo: '',
      mileage: null,
      mileage_notes: '',
      image: [],
    },
    temtTasks: [],
    selectedScheduledShift: {
      shift_tasks: '',
    },
    latestScheduledShift: {
    },
    shiftDATE: null,
  }),
  mutations: {
    SET_TASKS: (state, payload) => (state.tasks = payload),
    SET_TASK: (state, payload) => (state.task = payload),
    SET_TEMP_TASKS: (state, payload) => (state.temtTasks = payload),
    SET_SHIFT_DATE: (state, payload) => (state.shiftDATE = payload),
    SET_SELECTED_SCHEDULED_SHIFT: (state, payload) => (state.selectedScheduledShift = payload),
    SET_LATEST_SCHEDULE_SHIFT: (state, payload) => (state.latestScheduledShift = payload),
  },
  getters: {
    // getshiftID: state => state.shiftID,
  },
  actions: {
    requestAction: async ctx => {
      const formData = new FormData()
      const data = JSON.stringify({
        action: 'getById1',
        userId: JSON.parse(localStorage.userData).id,
        dateParam: ctx.state.shiftDATE,
      })
      formData.append('data', data)
      const accessToken = localStorage.getItem('accessToken')
      try {
        // const response = await axios.post(`${Vue.prototype.$apiUrl2}CaregiverDashboard/fetchCaregiverShiftInfo`, formData,
        const response = await axios.post(`${Vue.prototype.$apiUrl2}CaregiverDashboard/fetchSelectedShift`, formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
        return response
      } catch (error) {
        return error
      }
    },
    filterMyData(state, payload) {
      // const isLatestScheduledShift = payload.filter(f => (f.start > today && (f.status === '1' || f.status === '2'))).sort((a, b) => new Date(a.start) - new Date(b.start))
      const isLatestScheduledShift = payload.latestScheduledShift
      // eslint-disable-next-line no-unused-expressions
      isLatestScheduledShift.length !== 0 ? state.commit('SET_LATEST_SCHEDULE_SHIFT', isLatestScheduledShift) : ''
      // console.log('isLatestScheduledShift', isLatestScheduledShift)

      // const isSelectedScheduledShift = payload.filter(f => f.start === state.state.shiftDATE)
      const isSelectedScheduledShift = payload.selectedScheduledShift
      // eslint-disable-next-line no-unused-expressions
      isSelectedScheduledShift.length !== 0 ? state.dispatch('setSelectedScheduledShift', isSelectedScheduledShift) : state.commit('SET_SELECTED_SCHEDULED_SHIFT', {})
      // console.log('isSelectedScheduledShift', isSelectedScheduledShift)
    },
    setSelectedScheduledShift(ctx, payload) {
      const tempData = {
        shiftID: payload.id,
        caregiverId: payload.caregiver,
        clientID: payload.client,
        clientPicture: payload.clientPicture,
        client_name: payload.client_name,
        allDay: payload.allDay,
        start: payload.start,
        end: payload.end,
        start_time: `${moment(payload.start).format('YYYY/MM/DD')} ${payload.start_time}`,
        end_time: `${moment(payload.end).format('YYYY/MM/DD')} ${payload.end_time}`,
        status: payload.status ? payload.status : '',
        shift_type: payload.shift_type,
        shift_tasks: payload.shift_tasks ? payload.shift_tasks : '',
        clock_inout: payload.clock_inout ? payload.clock_inout : '',
        grace_period: payload.grace_period ? payload.grace_period : '',
      }
      ctx.commit('SET_SELECTED_SCHEDULED_SHIFT', tempData)
      // const sortedToDate = tempData.sort((a, b) => new Date(a.start) - new Date(b.start))
      // console.log('SET_SELECTED_SCHEDULED_SHIFT', tempData)
      // ctx.commit('SET_LATEST_SCHEDULE_SHIFT', sortedToDate.slice(0, 5))
    },
    onUpdateTaskData(ctx, formData) {
      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}CaregiverDashboard/uploadFile`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-type': 'multipart/form-data',
              },
            })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    //* FOR BULK OF TASKS UPDATE  *//
    // onUpdateTasksData(ctx, formData) {
    //   const accessToken = localStorage.getItem('accessToken')
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post(`${Vue.prototype.$apiUrl2}CaregiverDashboard/shiftSaveTasks`,
    //         formData,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${accessToken}`,
    //             'Content-type': 'multipart/form-data',
    //           },
    //         })
    //       .then(response => {
    //         resolve(response)
    //       })
    //       .catch(error => { reject(error) })
    //   })
    // },
    insertClockInData(ctx, formData) {
      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}CaregiverDashboard/setClockInData`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-type': 'multipart/form-data',
              },
            })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    onClockOut(ctx, formData) {
      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}CaregiverDashboard/uploadClockOutPhoto`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-type': 'multipart/form-data',
              },
            })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
  },
}
