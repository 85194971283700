<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isClockOutHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-clock-out-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 "
        >
          <div class="font-weight-bolder">
            {{ clockOutDataLocal.date }} | {{ clockOutDataLocal.start_time }} - {{ clockOutDataLocal.end_time }}
          </div>
          <span />
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onClockOut)"
          >
            <!-- worksafe/YN -->
            <!-- <span>Did you have a safe shift today?</span>
            <validation-provider
              #default="validationContext"
              name="Worksafe Status"
              rules="required"
            >
              <b-form-group
                for="worksafe status"
                class="mt-1"
              >
                <b-form-radio-group
                  id="worksafe-radios-1"
                  v-model="clockOutDataLocal.worksafe"
                  :options="statusOptions"
                  name="radio-status-validation"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->

            <!-- Reason -->
            <!-- <validation-provider
              #default="validationContext"
              name="Note"
              :rules="clockOutDataLocal.worksafe === 'no' ? 'required' : ''"
            >
              <b-form-group
                v-if="clockOutDataLocal.worksafe === 'no'"
                label="Reason"
                for="reason"
                class="font-weight-bolder"
              >
                <b-form-textarea
                  v-model="clockOutDataLocal.worksafecomment"
                  placeholder="Add notes here"
                  rows="5"
                  no-resize
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->

            <!-- Worksafe Photo -->
            <!-- <validation-provider
              #default="validationContext"
              name="Image"
              rules="required"
            > -->
            <b-form-group
              label="Photos"
              class="font-weight-bolder"
            >
              <b-form-file
                id="wsPhoto"
                v-model="clockOutDataLocal.worksafephoto"
                placeholder="Choose an image or drop it here..."
                drop-placeholder="Drop a photo here..."
                type="file"
                accept="image/jpg"
                capture
                class="d-none"
                @change="onWFileChange($event)"
              />
              <!-- <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback> -->
            </b-form-group>
            <!-- </validation-provider> -->

            <!-- Worksafe Photo Display-->
            <!-- <b-img
              v-if="myPhotoLocal !== ''"
              rounded
              fluid
              :src="`data:image/jpg;base64, ${myPhotoLocal}`"
              :alt="myPhotoLocal"
              class="d-inline-block mr-1 mb-1 myPhotos"
            /> -->
            <span>
              <!-- alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="showAlert"
                class="mb-1"
              >
                <div class="alert-body">
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-50"
                  />
                  Image size should be less than 1 MB
                </div>
              </b-alert>
              <span
                id=""
                class="cursor-pointer"
                @click="onClickPhoto"
              >
                <b-avatar
                  rounded
                  variant="light"
                  size="100"
                  class="d-inline-block mr-1 mb-1"
                  style="border-style: dashed; border-color: #B1BAC4;"
                >
                  <feather-icon
                    icon="CameraIcon"
                    size="62"
                    style="color: #B1BAC4;"
                  />
                </b-avatar>
              </span>
              <b-img
                v-if="tempWPhotoURL !== ''"
                rounded
                fluid
                :src="tempWPhotoURL"
                :alt="tempWPhotoURL"
                class="d-inline-block mr-1 mb-1 myPhotos"
              />
              <b-img
                v-if="tempWPhotoURL === '' && myPhotoLocal !== ''"
                rounded
                fluid
                :src="`${$apiUrl2}mobile/worksafe/${myPhotoLocal}`"
                :alt="myPhotoLocal"
                class="d-inline-block mr-1 mb-1 myPhotos"
              />
            </span>

            <!-- Mileage -->
            <div
              class="h5 font-weight-bolder mb-1"
            >Other Expenses</div>
            <b-card-body class="bg-light mb-1">
              <validation-provider
                #default="validationContext"
                name="expenses"
                rules="regex:^[+-]?\d+(\.\d+)?$"
              >
                <b-form-group for="expense">
                  <p class="mb-1 h6">
                    How much did you spent for other expenses?
                  </p>
                  <b-form-input
                    v-model="clockOutDataLocal.expenseamount"
                    v-mask="['######']"
                    autofocus
                    trim
                    placeholder="Enter amount here"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <!-- <validation-provider
                #default="validationContext"
                name="mileage"
                rules="required"
              > -->
              <b-form-group for="miles traveled">
                <p class="mb-1 h6">
                  What is it for?
                </p>
                <b-form-textarea
                  id="note"
                  v-model="clockOutDataLocal.expensenote"
                  placeholder="Add details here"
                  rows="5"
                  no-resize
                />
                <!-- <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback> -->
              </b-form-group>
              <!-- </validation-provider> -->
            </b-card-body>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                class="mr-2"
                :disabled="showAlert"
                type="submit"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-dark"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTextarea,
  // BFormRadioGroup,
  BFormFile,
  BCardBody,
  BFormInvalidFeedback,
  BImg,
  BAvatar,
  BAlert,
} from 'bootstrap-vue'
import { heightFade } from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from '@vue/composition-api'
import { mask } from 'vue-the-mask'
// import Swal from 'sweetalert2'
import useAgentClockOutHandlerSidebar from './useAgentClockOutHandlerSidebar'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    // BFormRadioGroup,
    BFormFile,
    BCardBody,
    BFormInvalidFeedback,
    BImg,
    BAvatar,
    BAlert,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    mask,
    'height-fade': heightFade,
  },
  model: {
    prop: 'isClockOutHandlerSidebarActive',
    event: 'update:is-clock-out-handler-sidebar-active',
  },
  props: {
    isClockOutHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    clockOutData: {
      type: Object,
      required: true,
    },
    clearClockOutData: {
      type: Function,
      required: true,
    },
  },
  unmounted() {
    this.style.remove()
  },
  setup(props, { emit }) {
    const {
      clockOutDataLocal,
      myPhotoLocal,
      showAlert,
      tempWPhotoURL,
      onWFileChange,
      onClickPhoto,
      resetClockOutLocal,

      // UI
      statusOptions,
      validationForm,
      onClockOut,
    } = useAgentClockOutHandlerSidebar(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetClockOutLocal, props.clearClockOutData)

    return {
      clockOutDataLocal,
      myPhotoLocal,
      showAlert,
      tempWPhotoURL,
      onWFileChange,
      onClickPhoto,
      statusOptions,
      validationForm,
      onClockOut,

      // Form Validation
      required,
      refFormObserver,
      getValidationState,

      // Filter/Formatter
      avatarText,
    }
  },
  // methods: {
  //   previewPhoto(params) {
  //     Swal.fire({
  //       text: params.photo,
  //       imageUrl: `${this.$urlUpload}agentTasks/image-files/${params.photo}`,
  //       imageAlt: params.photo,
  //       imageWidth: 400,
  //       returnFocus: false,
  //       confirmButtonColor: '#5f9ea0',
  //       showClass: {
  //         popup: 'swal2-show',
  //       },
  //       hideClass: {
  //         popup: 'swal2-hide',
  //       },
  //     })
  //   },
  // },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}
.myPhotos{
    width: 100px !important;
    height:100px !important;
    // cursor: pointer;
    object-fit: cover;
  }
</style>
