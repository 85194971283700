<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <div class="font-weight-bolder">
            Task: {{ taskLocal.description || taskLocal.task }}
          </div>
          <span />
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onUpdateTaskData)"
          >
            <!-- status -->
            <span
              v-if="taskLocal.status.toLowerCase() === 'completed'"
            >The task has been marked COMPLETED</span>
            <span
              v-else-if="taskLocal.status.toLowerCase() === 'not-completed'"
            >The task has been marked NOT-COMPLETED</span>
            <span v-else>Have you completed this task?</span>
            <validation-provider
              #default="validationContext"
              name="Task Status"
              rules=""
            >
              <b-form-group
                for="task status"
                class="mt-1"
              >
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="taskLocal.status"
                  :options="statusOptions"
                  name="radio-status-validation"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Notes -->
            <!-- <validation-provider
              #default="validationContext"
              name="Note"
              rules="required"
            > -->
            <b-form-group
              label="Notes"
              for="notes"
              class="font-weight-bolder"
            >
              <b-form-textarea
                id="note"
                v-model="taskLocal.notes"
                placeholder="Add notes here"
                rows="5"
                no-resize
              />
              <!-- <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback> -->
            </b-form-group>
            <!-- </validation-provider> -->

            <!-- Mileage -->
            <label
              for="mileage"
              class="h5 font-weight-bolder mb-1"
            >Mileage</label>
            <b-card-body class="bg-light mb-1">
              <validation-provider
                #default="validationContext"
                name="mileage transportation"
                rules=""
              >
                <b-form-group for="transportation option">
                  <p class="mb-1 h6">
                    Form of transportation
                  </p>
                  <b-form-radio-group
                    id="btn-radios-2"
                    v-model="taskLocal.mileage_transpo"
                    :options="transportationOption"
                    name="radio-transpo-validation"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <!-- <validation-provider
                #default="validationContext"
                name="mileage"
                rules="regex:^[+-]?\d+(\.\d+)?$"
              > -->
              <validation-provider
                #default="validationContext"
                name="mileage"
                rules="regex:^[+-]?\d+(\.\d+)?$"
              >
                <b-form-group for="miles traveled">
                  <p class="mb-1 h6">
                    How many miles did you travel?
                  </p>
                  <b-form-input
                    v-model="taskLocal.mileage"
                    autofocus
                    trim
                    placeholder="Miles"
                    :state="getValidationState(validationContext)"
                  />
                  <!-- <b-form-input
                    v-model="taskLocal.mileage"
                    v-mask="['##', '##.##', '###.##', '####.##', '#####.##']"
                    autofocus
                    trim
                    placeholder="Miles"
                    :state="getValidationState(validationContext)"
                    :disabled="taskToAlertsLocal.shiftStatus === '4' || taskToAlertsLocal.curClockOut ? true:false"
                  /> -->
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <!-- <validation-provider
              #default="validationContext"
              name="mileage transportation"
              rules="required"
            > -->
              <b-form-group for="mileage notes">
                <p class="mb-1 h6">
                  Notes
                </p>
                <b-form-textarea
                  id="note"
                  v-model="taskLocal.mileage_notes"
                  placeholder="Add notes here"
                  rows="5"
                  no-resize
                />
                <!-- <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback> -->
              </b-form-group>
              <!-- </validation-provider> -->
            </b-card-body>

            <!-- Image -->
            <!-- <validation-provider
              #default="validationContext"
              name="Image"
              rules="required"
            > -->
            <b-form-group
              label="Shift Photos"
              class="font-weight-bolder"
            >
              <b-form-file
                id="tPhoto"
                v-model="taskLocal.image"
                placeholder="Choose a photo or drop it here..."
                drop-placeholder="Drop a photo here..."
                type="file"
                accept="image/*"
                capture
                class="d-none"
                @change="onFileChange($event)"
              />
              <!-- <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback> -->
            </b-form-group>
            <!-- </validation-provider> -->

            <!-- Display Photos -->
            <span>
              <!-- alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="showAlert"
                class="mb-1"
              >
                <div class="alert-body">
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-50"
                  />
                  Image size should be less than 1 MB
                </div>
              </b-alert>
              <span
                id=""
                class="cursor-pointer"
                @click="onClickImage"
              >
                <b-avatar
                  rounded
                  variant="light"
                  size="100"
                  class="d-inline-block mr-1 mb-1"
                  style="border-style: dashed; border-color: #B1BAC4;"
                >
                  <feather-icon
                    icon="CameraIcon"
                    size="62"
                    style="color: #B1BAC4;"
                  />
                </b-avatar>
              </span>
              <b-img
                v-if="tempPhotoURL !== ''"
                rounded
                fluid
                :src="tempPhotoURL"
                :alt="tempPhotoURL"
                class="d-inline-block mr-1 mb-1 myPhotos"
              />
              <b-img
                v-if="tempPhotoURL === '' && myImageLocal !== ''"
                rounded
                fluid
                :src="`${$apiUrl2}mobile/tasks/${myImageLocal}`"
                :alt="myImageLocal"
                class="d-inline-block mr-1 mb-1 myPhotos"
              />
            </span>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                class="mr-2"
                :disabled="showAlert"
                type="submit"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-dark"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTextarea,
  BFormRadioGroup,
  BFormFile,
  BCardBody,
  BFormInvalidFeedback,
  BImg,
  BAvatar,
  BAlert,
} from 'bootstrap-vue'
import { heightFade } from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from '@vue/composition-api'
import { mask } from 'vue-the-mask'
// import Swal from 'sweetalert2'
import useAgentTasksHandlerSidebar from './useAgentTasksHandlerSidebar'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormRadioGroup,
    BFormFile,
    BCardBody,
    BFormInvalidFeedback,
    BImg,
    BAvatar,
    BAlert,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    mask,
    'height-fade': heightFade,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    taskToAlerts: {
      type: Object,
      required: true,
    },
    clearTaskData: {
      type: Function,
      required: true,
    },
  },
  unmounted() {
    this.style.remove()
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, clearForm,
    } = formValidation(
      props.clearTaskData,
    )

    const {
      taskLocal,
      taskToAlertsLocal,
      myImageLocal,
      tempPhotoURL,
      showAlert,
      onClickImage,
      onFileChange,

      // UI
      statusOptions,
      transportationOption,
      myTaskStatus,
      validationForm,
      onUpdateTaskData,
    } = useAgentTasksHandlerSidebar(toRefs(props), clearForm, emit)

    return {
      taskToAlertsLocal,
      taskLocal,
      myImageLocal,
      tempPhotoURL,
      showAlert,
      onClickImage,
      onFileChange,
      statusOptions,
      transportationOption,
      myTaskStatus,
      validationForm,
      onUpdateTaskData,

      // Form Validation
      clearForm,
      required,
      refFormObserver,
      getValidationState,

      // Filter/Formatter
      avatarText,
    }
  },
  // methods: {
  //   previewPhoto(params) {
  //     Swal.fire({
  //       text: params.photo,
  //       imageUrl: `${this.$urlUpload}agentTasks/image-files/${params.photo}`,
  //       imageAlt: params.photo,
  //       imageWidth: 400,
  //       returnFocus: false,
  //       confirmButtonColor: '#5f9ea0',
  //       showClass: {
  //         popup: 'swal2-show',
  //       },
  //       hideClass: {
  //         popup: 'swal2-hide',
  //       },
  //     })
  //   },
  // },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}
.myPhotos {
  width: 100px !important;
  height: 100px !important;
  // cursor: pointer;
  object-fit: cover;
}
.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #5e5873;
}
.upload-photos{
  border: dotted 3px #B1BAC4;
}
.upload-photos:hover{
  opacity: 0.8;
}
</style>
