import { ref, watch } from '@vue/composition-api'

export default function useAgentClockOutHandlerSidebar(props, emit) {
  // ------------------------------------------------
  // Local data
  // ------------------------------------------------
  const clockOutDataLocal = ref(JSON.parse(JSON.stringify(props.clockOutData.value)))
  const myPhotoLocal = ref('')
  const tempWPhotoURL = ref('')
  const showAlert = ref(false)
  watch(props.clockOutData, () => {
    clockOutDataLocal.value = JSON.parse(JSON.stringify(props.clockOutData.value))
    myPhotoLocal.value = clockOutDataLocal.value.worksafephoto
    // console.log('clockOutDataLocal.value.worksafephoto', clockOutDataLocal.value.worksafephoto)
  })

  // *===============================================---*
  // *--------- CLOCK-OUT------------------------------*
  // *===============================================---*
  const onClockOut = () => {
    const formData = new FormData()
    // eslint-disable-next-line no-plusplus
    // for (let i = 0; i < clockOutDataLocal.value.image.length; i++) {
    //   const file = clockOutDataLocal.value.image[i]
    //   formData.append(`files[${i}]`, file)
    // }
    formData.append('file', clockOutDataLocal.value.worksafephoto)
    const data = JSON.stringify({
      // person_id: clockOutDataLocal.value.caregiverid,
      // type: 'caregiver',
      // data_id: clockOutDataLocal.value.client_id,
      // data_type: 'client',
      // data_name: clockOutDataLocal.value.client_name,
      // data_date: clockOutDataLocal.value.date,
      // data_start_time: clockOutDataLocal.value.start_time,
      // data_end_time: clockOutDataLocal.value.end_time,
      // data_shift_id: clockOutDataLocal.value.shiftID,

      id: clockOutDataLocal.value.id,
      // worksafe: clockOutDataLocal.value.worksafe ? clockOutDataLocal.value.worksafe : 'yes',
      expensenote: clockOutDataLocal.value.expensenote,
      expenseamount: clockOutDataLocal.value.expenseamount,
      // worksafecomment: clockOutDataLocal.value.worksafe !== 'yes' ? clockOutDataLocal.value.worksafecomment : '',
      worksafephoto: clockOutDataLocal.value.worksafephoto,
      worksafephotojpg: clockOutDataLocal.value.worksafephoto,
      rawDate: clockOutDataLocal.value.rawDate,
      shiftID: clockOutDataLocal.value.shiftID,
      status: '3',

    })
    formData.append('data', data)
    // console.log('some error message', data)
    emit('on-clock-out', formData)
    // Close sidebar
    emit('update:is-clock-out-handler-sidebar-active', false)
  }

  const onClickPhoto = () => {
    document.getElementById('wsPhoto').click()
  }

  const onWFileChange = e => {
    const file = e.target.files[0]
    if (e.target.files.length > 0) {
      tempWPhotoURL.value = URL.createObjectURL(file)
      if (file.size > 1048576) showAlert.value = true
      else showAlert.value = false
    } else {
      tempWPhotoURL.value = ''
    }
  }

  // ------------------------------------------------
  // isClockOutHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  watch(props.isClockOutHandlerSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        tempWPhotoURL.value = ''
        myPhotoLocal.value = clockOutDataLocal.value.worksafephoto ? clockOutDataLocal.value.worksafephoto : ''
      }, 250)
    }
  })
  // hide scrollbar of parent container
  watch(props.isClockOutHandlerSidebarActive, val => {
    const appContainer = document.querySelector('#app')
    if (!val) {
      appContainer.style.overflow = ''
    } else {
      appContainer.style.overflow = 'hidden'
    }
  })

  // ------------------------------------------------
  // Options
  // ------------------------------------------------
  const statusOptions = [
    { text: 'Yes', value: 'yes' },
    { text: 'No', value: 'no' },
  ]

  return {
    clockOutDataLocal,
    myPhotoLocal,
    showAlert,
    tempWPhotoURL,
    onWFileChange,
    onClickPhoto,

    // UI
    statusOptions,

    onClockOut,
  }
}
