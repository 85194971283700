import { ref, watch } from '@vue/composition-api'

export default function useAgentTasksHandlerSidebar(props, clearForm, emit) {
  // ------------------------------------------------
  // taskLocal
  // ------------------------------------------------
  // const tempTaskLocal = ref(JSON.parse(JSON.stringify(props.task.value)))
  const taskLocal = ref(JSON.parse(JSON.stringify(props.task.value)))
  const taskToAlertsLocal = ref(JSON.parse(JSON.stringify(props.taskToAlerts.value)))
  const myImageLocal = ref('')
  const tempPhotoURL = ref('')
  const showAlert = ref(false)
  watch(props.task, () => {
    taskLocal.value = JSON.parse(JSON.stringify(props.task.value))
    taskToAlertsLocal.value = JSON.parse(JSON.stringify(props.taskToAlerts.value))
    myImageLocal.value = taskLocal.value.image
    // console.log('myImageLocal.value', myImageLocal.value)
  })

  // *===============================================---*
  // *--------- UPDATE DATA -----------------------------*
  // *===============================================---*
  const onUpdateTaskData = () => {
    const formData = new FormData()
    // for (let i = 0; i < taskLocal.value.image.length; i++) {
    //   const file = taskLocal.value.image[i]
    //   formData.append(`files[${i}]`, file)
    // }
    formData.append('file', taskLocal.value.image)
    const data = JSON.stringify({
      status: taskLocal.value.status || 'not-completed',
      notes: taskLocal.value.notes,
      mileage_transpo: taskLocal.value.mileage_transpo,
      mileage: taskLocal.value.mileage,
      mileage_notes: taskLocal.value.mileage_notes,
      image: taskLocal.value.image || null,
      id: taskLocal.value.id,
      clock_inout_id: taskToAlertsLocal.value.clock_inout_id,

      person_id: taskToAlertsLocal.value.caregiverid,
      type: 'caregiver',
      data_id: taskToAlertsLocal.value.client_id,
      data_type: 'client',
      data_name: taskToAlertsLocal.value.client_name,
      data_task: taskLocal.value.description || taskLocal.value.task,
      data_date: taskToAlertsLocal.value.date,
      data_time: taskToAlertsLocal.value.time,
    })

    formData.append('data', data)
    // console.log('taskLocal.value: ', formData.get('file'))
    emit('update-tasks', formData)
    // Close sidebar
    emit('update:is-task-handler-sidebar-active', false)
  }

  const onClickImage = () => {
    document.getElementById('tPhoto').click()
  }

  const onFileChange = e => {
    const file = e.target.files[0]
    if (e.target.files.length > 0) {
      tempPhotoURL.value = URL.createObjectURL(file)
      if (file.size > 1048576) showAlert.value = true
      else showAlert.value = false
    } else {
      tempPhotoURL.value = ''
    }
  }

  // ------------------------------------------------
  // isTaskHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  watch(props.isTaskHandlerSidebarActive, val => {
    // ? Don't reset event till transition is finished
    // console.log('val: ', val)
    if (!val) {
      setTimeout(() => {
        tempPhotoURL.value = ''
        // myImageLocal.value = taskLocal.value.image === Object ? taskLocal.value.image : ''
      }, 100)
    }
  })
  // hide scrollbar of parent container
  watch(props.isTaskHandlerSidebarActive, val => {
    const appContainer = document.querySelector('#app')
    if (!val) {
      appContainer.style.overflow = ''
    } else {
      appContainer.style.overflow = 'hidden'
    }
  })

  // ------------------------------------------------
  // Options
  // ------------------------------------------------
  const statusOptions = [
    { text: 'Yes', value: 'completed' },
    { text: 'No', value: 'not-completed' },
  ]

  const transportationOption = [
    { text: 'Commuting', value: 'commuting' },
    { text: 'Driving', value: 'driving' },
  ]

  return {
    taskLocal,
    taskToAlertsLocal,
    myImageLocal,
    tempPhotoURL,
    showAlert,
    onClickImage,
    onFileChange,
    onUpdateTaskData,

    // UI
    statusOptions,
    transportationOption,
  }
}
