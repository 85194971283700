<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
  >
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item
                to="/"
                @click="
                  $router.push({
                    name: 'caregiverLanding',
                  })
                "
              >
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item :active="true">
                {{ pageTitle }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-card class="mt-1 h-75-per">
      <b-card-text class="d-flex justify-content-between align-items-center mb-2">
        <small>
          <b-card-text class="h3 mb-0">
            <b-link
              class="text-dark"
              :to="{ name: 'caregiver-client-profile', params: {}, query: { id: selectedScheduledShift.clientID } }"
            >
              {{ selectedScheduledShift.client_name }}
            </b-link>
          </b-card-text>
          <span
            v-if="selectedScheduledShift.start"
            class="text-muted"
          >
            {{ moment(selectedScheduledShift.start).format('ddd, MMM D, YYYY') }}
            |
            {{ moment(selectedScheduledShift.start_time).format('h:mm a') }}
            -
            {{ moment(selectedScheduledShift.end_time).format('h:mm a') }}
          </span>
        </small>
        <!-- {{ !(moment(selectedScheduledShift.start).format('MM/DD/YYYY') === today) }} -->
        <!-- {{ !(moment(selectedScheduledShift.start).format('MM/DD/YYYY') === today) }} -->
        <b-button
          v-if="['1', 1].includes(selectedScheduledShift.status)"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          class="text-white gradBTN"
          style="width: 18em"
          :disabled="!(selectedScheduledShift.start === latestScheduledShift.start || selectedScheduledShift.allDay) || isScheduledShiftToday"
          @click="onClockIn()"
        >
          Clock-In
        </b-button>
        <b-button
          v-if="['2', '3', 2, 3].includes(selectedScheduledShift.status)"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          class="text-white gradBTNOUT"
          style="width: 18em"
          :disabled="canClockOut"
          @click="handleClockOutClick()"
        >
          Clock-Out
        </b-button>
      </b-card-text>
      <b-list-group
        flush
        class="border-bottom border-top h6"
      >
        <b-list-group-item
          v-for="(item, index) in selectedScheduledShift.shift_tasks"
          :key="index"
          class="d-flex justify-content-between align-items-center pt-1 pb-1"
          @click="['2', '3', 2, 3].includes(selectedScheduledShift.status) ? handleTaskClick(item) : ''"
        >
          <b-media
            vertical-align="top"
          >
            <template #aside>
              <span
                v-if="selectedScheduledShift.clock_inout && ['2', '3', 2, 3].includes(selectedScheduledShift.status)"
              >
                <b-icon-check-square-fill
                  v-if="item.status.toLowerCase() === 'completed'"
                  variant="primary"
                  font-size="18"
                />
                <b-icon-x-square-fill
                  v-else-if="item.status.toLowerCase() === 'not-completed'"
                  variant="danger"
                  font-size="18"
                />
                <b-icon-square-fill
                  v-else
                  style="color: #B1BAC4;"
                  font-size="18"
                />
              </span>
            </template>
            <!-- <span>{{ selectedScheduledShift.clock_inout && !selectedScheduledShift.grace_period ? item.description :item.task }} </span> -->
            <span
              class=""
              style="color: #516F90;"
            >
              {{ item.description || item.task }}
            </span>
          </b-media>
          <span class="h6">
            <!-- {{ moment(moment(selectedScheduledShift.start).format('MM/DD/YYYY') + ' ' + item.start_time.split(" ")[1]).format('hh:mm a') }} -->
          </span>
        </b-list-group-item>
        <b-list-group-item v-if="selectedScheduledShift.shift_tasks.length === 0">
          <div class="d-flex flex-row justify-content-center align-items-center">
            <h5>No records found</h5>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <!-- Task Handler -->
    <agent-task-handler-sidebar
      v-model="isTaskHandlerSidebarActive"
      :task="task"
      :task-to-alerts="taskToAlerts"
      :clear-task-data="clearTaskData"
      @update-tasks="onUpdateTaskData"
    />
    <!-- Task Handler For Clock-out-->
    <agent-clock-out-handler-sidebar
      v-model="isClockOutHandlerSidebarActive"
      :clock-out-data="clockOutData"
      :clear-clock-out-data="clearClockOutData"
      @on-clock-out="onClockOut"
    />
  </b-overlay>
</template>

<script>
import SockJS from 'sockjs-client'
import {
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BMedia,
  BButton,
  BIconCheckSquareFill,
  BIconXSquareFill,
  BIconSquareFill,
  BOverlay,
  BAvatar,
  BLink,
  BRow,
  BCol,
  BBreadcrumb,
  BBreadcrumbItem,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { formatDate, avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import Swal from 'sweetalert2'
// Notification
import { useToast } from 'vue-toastification/composition'
// eslint-disable-next-line no-unused-vars
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import agentTasksStoreModule from './agentTasksStoreModule'
import AgentTaskHandlerSidebar from './AgentTaskHandlerSidebar.vue'
import AgentClockOutHandlerSidebar from './AgentClockOutHandlerSidebar.vue'

export default {
  components: {
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BMedia,
    BButton,
    BOverlay,
    BIconCheckSquareFill,
    BIconXSquareFill,
    BIconSquareFill,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BLink,
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    AgentTaskHandlerSidebar,
    AgentClockOutHandlerSidebar,
  },
  directives: {
    Ripple,
  },
  unmounted() {
    this.style.remove()
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
      SockJS.call(this.sock, this.sock.url)
    }
  },
  setup() {
    const CAREGIVER_TASK_STORE_MODULE_NAME = 'agenttasks'
    // Register module
    if (!store.hasModule(CAREGIVER_TASK_STORE_MODULE_NAME)) store.registerModule(CAREGIVER_TASK_STORE_MODULE_NAME, agentTasksStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CAREGIVER_TASK_STORE_MODULE_NAME)) store.unregisterModule(CAREGIVER_TASK_STORE_MODULE_NAME)
    })
    const isLoading = ref(true)
    // Use toast
    // eslint-disable-next-line no-unused-vars
    const toast = useToast()
    // const date = new Date()
    const date = new Date(new Date().getTime() - 2 * 60 * 60 * 1000)
    const pst = new Date().toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
    })
    const today = moment(pst).format('MM/DD/YYYY')
    // console.log('today pst', today)
    // console.log('date1', date)
    // console.log('date2', new Date())
    const tasks = ref(computed(() => store.state.agenttasks.tasks))
    const selectedScheduledShift = ref(computed(() => store.state.agenttasks.selectedScheduledShift))
    const latestScheduledShift = ref(computed(() => store.state.agenttasks.latestScheduledShift))
    const shiftStartDate = router.currentRoute.query.date
    const isTaskHandlerSidebarActive = ref(false)
    const isClockOutHandlerSidebarActive = ref(false)
    const clockOutData = ref({})
    const canClockOut = ref(true)
    const blankTask = {
      status: '',
      description: '',
      notes: '',
      mileage_transpo: '',
      mileage: null,
      mileage_notes: '',
      image: [],
    }
    const blankClockOut = {
      worksafe: '',
      expenseamount: '',
      expensenote: '',
      worksafephoto: [],
    }
    const task = ref(store.state.agenttasks.task)
    const taskToAlerts = ref({})
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }
    const clearClockOutData = () => {
      clockOutData.value = JSON.parse(JSON.stringify(blankClockOut))
    }
    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-dw01'
    const sockIdNotif = 'coc-n01'
    const getSockMsg = sockMsg => {
      if (sockMsg === true) {
        // Trigger reload or something here
        // console.log(sockMsg)
      }
    }

    const sendSockMsg = sockMsg => {
      sock.send(JSON.stringify({ id: sockId, msg: sockMsg }))
      sock.send(JSON.stringify({ id: sockIdNotif, msg: sockMsg }))
    }

    const isScheduledShiftToday = computed(() => !(moment(selectedScheduledShift.value.start).format('MM/DD/YYYY') === today))

    // *===============================================---*
    // *--------- RETRIEVE DATA ---------------------------*
    // *===============================================---*
    const fetchMyData = () => {
      isLoading.value = true
      store.commit('agenttasks/SET_SHIFT_DATE', shiftStartDate)
      store.dispatch('agenttasks/requestAction')
        .then(response => {
          store.dispatch('agenttasks/filterMyData', response.data.response.data)
          // console.log('shiftDeatils', response.data.response.data)
          const countCompTask = selectedScheduledShift.value.shift_tasks.filter(x => x.status !== '')
          const shifttasksLength = selectedScheduledShift.value.shift_tasks.length
          if (countCompTask.length === shifttasksLength) {
            canClockOut.value = false
          }
          isLoading.value = false
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong on displaying data!',
            showConfirmButton: false,
            timer: 1500,
          })
          isLoading.value = false
        })
    }
    fetchMyData()

    // *===============================================---*
    // *--------- OPEN SIDEBAR AND PASS DATA ---------------*
    // *===============================================---*
    const handleTaskClick = taskData => {
      const tempTask = {
        shiftStatus: selectedScheduledShift.value.status,
        date: selectedScheduledShift.value.start_time,
        time: formatDate(selectedScheduledShift.value.start_time, { hour: 'numeric', minute: 'numeric' }),
        client_id: selectedScheduledShift.value.clientID,
        caregiverid: selectedScheduledShift.value.caregiverId,
        client_name: selectedScheduledShift.value.client_name,
        clock_inout_id: selectedScheduledShift.value.clock_inout.id,
      }
      store.commit('agenttasks/SET_TASK', taskData)
      // console.log('taskData: ', taskData)
      task.value = taskData
      taskToAlerts.value = tempTask
      isTaskHandlerSidebarActive.value = !isTaskHandlerSidebarActive.value
    }
    const handleClockOutClick = () => {
      const temp = {
        date: formatDate(selectedScheduledShift.value.start, {
          weekday: 'short', month: 'short', year: 'numeric', day: 'numeric',
        }),
        start_time: formatDate(selectedScheduledShift.value.start_time, { hour: 'numeric', minute: 'numeric' }),
        end_time: formatDate(selectedScheduledShift.value.end_time, { hour: 'numeric', minute: 'numeric' }),
        client_id: selectedScheduledShift.value.clientID,
        caregiverid: selectedScheduledShift.value.caregiverId,
        client_name: selectedScheduledShift.value.client_name,
        shiftID: selectedScheduledShift.value.shiftID,

        id: selectedScheduledShift.value.clock_inout.id,
        worksafe: selectedScheduledShift.value.clock_inout.worksafe ? selectedScheduledShift.value.clock_inout.worksafe : 'yes',
        expensenote: selectedScheduledShift.value.clock_inout.expensenote,
        expenseamount: selectedScheduledShift.value.clock_inout.expenseamount,
        worksafephoto: selectedScheduledShift.value.clock_inout.worksafephoto,
        worksafecomment: selectedScheduledShift.value.clock_inout.worksafecomment,
        rawDate: moment(selectedScheduledShift.value.start).format('YYYY-MM-DD'),
      }
      clockOutData.value = temp
      isClockOutHandlerSidebarActive.value = !isClockOutHandlerSidebarActive.value
    }

    // *===============================================---*
    // *--------- CLOCK-IN FUNCTION ---------------------*
    // *===============================================---*
    const onClockIn = () => {
      const formData = new FormData()
      const data = JSON.stringify({
        shift_id: selectedScheduledShift.value.shiftID,
        caregiverid: selectedScheduledShift.value.caregiverId,
        date: selectedScheduledShift.value.start_time,
        tasks: selectedScheduledShift.value.shift_tasks,
        status: '2',
        clock_in: moment(date).format('YYYY-MM-DD hh:mm:ss'),
      })
      formData.append('data', data)
      // console.log('formData', data)
      store.dispatch('agenttasks/insertClockInData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Clock-in completed',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          // sendSockMsg(true)
          fetchMyData()
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
        })
    }

    // *===============================================---*
    // *------------- UPDATE OF TASKS ---------------------*
    // *===============================================---*
    const onUpdateTaskData = _task => {
      // const self = this
      store.dispatch('agenttasks/onUpdateTaskData', _task)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          sendSockMsg(true)
          fetchMyData()
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
        })
    }
    // *===============================================---*
    // *------------- UPDATE OF BULK TASKS ---------------------*
    // *===============================================---*
    // const onUpdateTasksData = () => {
    //   const countCompTask = selectedScheduledShift.value.shift_tasks.filter(x => x.status === '')
    //   const formData = new FormData()
    //   const data = JSON.stringify({
    //     tasks: countCompTask,
    //     person_id: selectedScheduledShift.value.caregiverId,
    //     type: 'caregiver',
    //     data_id: selectedScheduledShift.value.clientID,
    //     data_type: 'client',
    //     data_name: selectedScheduledShift.value.client_name,
    //     data_date: selectedScheduledShift.value.start_time,
    //     data_time: formatDate(selectedScheduledShift.value.start_time, { hour: 'numeric', minute: 'numeric' }),
    //   })
    //   formData.append('data', data)
    //   Swal.fire({
    //     title: 'Empty task status detected!',
    //     text: 'Would you like to continue and set the status to not-completed?',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Continue',
    //     customClass: {
    //       confirmButton: 'btn btn-danger mr-1',
    //     },
    //   }).then(result => {
    //     /* Read more about isConfirmed, isDenied below */
    //     if (result.isConfirmed) {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Updated to not-completed',
    //           icon: 'CheckIcon',
    //           variant: 'success',
    //         },
    //       })
    //       store.dispatch('agenttasks/onUpdateTasksData', formData)
    //         .then(() => {
    //           handleClockOutClick()
    //           sendSockMsg(true)
    //           fetchMyData()
    //         })
    //         .catch(error => {
    //           console.error(error)
    //           toast({
    //             component: ToastificationContent,
    //             props: {
    //               title: 'Oops...',
    //               text: 'Something went wrong!',
    //               icon: 'AlertTriangleIcon',
    //               variant: 'danger',
    //             },
    //           })
    //         })
    //     }
    //   })
    // }
    const onClockOut = _clockOutData => {
      store.dispatch('agenttasks/onClockOut', _clockOutData)
        .then(() => {
          fetchMyData()
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          // sendSockMsg(true)
          router.push({
            name: 'caregiverLanding',
          })
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
        })
    }

    return {
      pageTitle: 'My Tasks',
      isLoading,
      today,
      tasks,
      selectedScheduledShift,
      latestScheduledShift,
      shiftStartDate,
      fetchMyData,
      onClockIn,
      onClockOut,
      isTaskHandlerSidebarActive,
      isClockOutHandlerSidebarActive,
      task,
      taskToAlerts,
      handleTaskClick,
      clockOutData,
      handleClockOutClick,
      onUpdateTaskData,
      // onUpdateTasksData,
      clearTaskData,
      clearClockOutData,
      canClockOut,
      sock,
      sockId,
      getSockMsg,
      sendSockMsg,
      isScheduledShiftToday,
      // UI
      formatDate,
      moment,
      avatarText,
    }
  },
}
</script>

<style>
  h1{
    margin: auto;
  }
  .gradBTN {
    background: transparent linear-gradient(90deg, #B7CF3E 0%, #329D9C 100%) 0% 0% no-repeat;
    opacity: 1;
  }
  .gradBTNOUT {
    background: transparent linear-gradient(90deg, #569099 0%, #98A1AB 100%) 0% 0% no-repeat;
    opacity: 1;
  }
</style>
